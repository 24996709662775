import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/Home/Home";
import ImpactInvesting from "./pages/ImpactInvesting/ImpactInvesting";
import NabBangladesh from "./pages/NabBangladesh/NabBangladesh";
import OurPeople from "./pages/OurPeople/OurPeople";
import ImpactInvestingBangladesh from "./pages/ImpactInvestingBangladesh/ImpactInvestingBangladesh";
import JoinNabBangladesh from "./pages/JoinNabBangladesh/JoinNabBangladesh";
import LearningHub from "./pages/LearningHub/LearningHub";
import NewsEvents from "./pages/NewsEvents/NewsEvents";
import BisapNews from "./pages/BisapNews/BisapNews";
import GsgNews from "./pages/GsgNews/GsgNews";
import ContactUs from "./pages/ContactUs/ContactUs";
import { useCollection } from "./hooks/useCollection";
import AdminLogin from "./pages/AdminLogin/AdminLogin";
import Admin from "./pages/Admin/Admin";
import ProtectedRoute from "./ProtectedRoute/ProtectedRoute";
import AdminJoinFormTable from "./pages/Admin/AdminJoinFormTable";
import AdminJoinFormContactTable from "./pages/Admin/AdminJoinFormContactTable";
import ScrollToTop from "./shared/utils/ScrollToTop";
import { FaAngleUp } from "react-icons/fa";
import { WindowHeight } from "./shared/utils/WindowHeight";
import BalancePriorities from "./pages/NewsDetails/BalancePriorities/BalancePriorities";
import Ific from "./pages/NewsDetails/Ific/Ific";
import OnImpact from "./pages/NewsDetails/OnImpact/OnImpact";
import NabMeeting from "./pages/NewsDetails/NabMeeting/NabMeeting";
import NabTrust from "./pages/NewsDetails/NabTrust/NabTrust";
import Partnership from "./pages/NewsDetails/Partnership/Partnership";
import ActualNabTrust from "./pages/NewsDetails/ActualNabTrust/ActualNabTrust";
import FatherOfImpactInvestment from "./pages/NewsDetails/FatherOfImpactInvestment/FatherOfImpactInvestment";
import NewWave from "./pages/NewsDetails/NewWave/NewWave";
import ImpactInvestar from "./pages/NewsDetails/ImpactInvestar/ImpactInvestar";
import ImpactInvestart from "./pages/NewsDetails/ImpactInvestart/ImpactInvestart";
import BridgingGap from "./pages/NewsDetails/BridgingGap/BridgingGap";
import ImpactAudit from "./pages/NewsDetails/ImpactAudit/ImpactAudit";
import EmpoweringImpact from "./pages/NewsDetails/EmpoweringImpact/EmpoweringImpact";
import FactstatsVsImpact from "./pages/NewsDetails/FactstatsVsImpact/FactstatsVsImpact";
import NationalImpactInvest from "./pages/NewsDetails/NationalImpactInvest/NationalImpactInvest";
import FosteringDevelopment from "./pages/NewsDetails/FosteringDevelopment/FosteringDevelopment";
import NationalImpactNovember from "./pages/NewsDetails/NationalImpactNovember/NationalImpactNovember";
import TimeTravellers from "./pages/NewsDetails/TimeTravellers/TimeTravellers";
import ConnecetingCreativity from "./pages/NewsDetails/ConnecetingCreativity/ConnecetingCreativity";
import GsgSummit from "./pages/NewsDetails/GsgSummit/GsgSummit";
import NationalAdvisory from "./pages/NewsDetails/NationalAdvisory/NationalAdvisory";
import FirstFundRaising from "./pages/NewsDetails/FirstFundRaising/FirstFundRaising";
import NavigatingImpact from "./pages/NewsDetails/NavigatingImpact/NavigatingImpact";
import FirstWorkshop from "./pages/NewsDetails/FirstWorkshop/FirstWorkshop";
import LaunchImpactInvestment from "./pages/NewsDetails/LaunchImpactInvestment/LaunchImpactInvestment";
import InvestmentStrategy from "./pages/NewsDetails/InvestmentStrategy/InvestmentStrategy";
import ConnectingCreativity from "./pages/NewsDetails/ConnectingCreativity/ConnectingCreativity";
import GsgGlobalSummit from "./pages/NewsDetails/GsgGlobalSummit/GsgGlobalSummit";
import InvesterAndIsm from "./pages/NewsDetails/InvesterAndIsm/InvesterAndIsm";
import NeedPopulation from "./pages/NewsDetails/NeedPopulation/NeedPopulation";
import InvestmentImpact from "./pages/NewsDetails/InvestmentImpact/InvestmentImpact";
import NationalImpact from "./pages/NewsDetails/NationalImpact/NationalImpact";
import PlanBreak from "./pages/NewsDetails/PlanBreak/PlanBreak";
import StrongGrowth from "./pages/NewsDetails/StrongGrowth/StrongGrowth";
import HnbAndLiin from "./pages/NewsDetails/HnbAndLiin/HnbAndLiin";
import TrillionEconomy from "./pages/NewsDetails/TrillionEconomy/TrillionEconomy";

function App() {
  const { document } = useCollection("admin");

  useEffect(() => {
    if (document.length !== 0)
      localStorage.setItem("nab_credentials", JSON.stringify(document));
  }, [document]);

  const handleTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const height = WindowHeight();

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/impact-inveseting" element={<ImpactInvesting />} />
        <Route path="/nab-bangladesh" element={<NabBangladesh />} />
        <Route path="/our-people" element={<OurPeople />} />
        <Route
          path="/impact-investing-in-bangladesh"
          element={<ImpactInvestingBangladesh />}
        />
        <Route path="/join-nab-bangladesh/" element={<JoinNabBangladesh />} />
        <Route path="/learning-hub" element={<LearningHub />} />
        <Route path="/news-events" element={<NewsEvents />} />
        <Route
          path="/the-impact-investars-of-bangladesh"
          element={<ImpactInvestar />}
        />
        <Route
          path="/balance-priorities-for-early-wins-in-biisap-implementation"
          element={<BalancePriorities />}
        />
        <Route
          path="/connecting-creativity-2022"
          element={<ConnectingCreativity />}
        />
        <Route
          path="/gsg-global-impact-summit-2023"
          element={<GsgGlobalSummit />}
        />
        <Route
          path="/impact-investar-and-impact-invest-ism"
          element={<InvesterAndIsm />}
        />
        <Route
          path="/we-need-population-weighted-aum"
          element={<NeedPopulation />}
        />
        <Route
          path="/first-international-impact-investment-workshop-in-bangladesh"
          element={<FirstWorkshop />}
        />
        <Route
          path="/launch-of-impact-investment"
          element={<LaunchImpactInvestment />}
        />
        <Route
          path="/bangladesh-impact-investment-strategy-and-action-plan"
          element={<InvestmentStrategy />}
        />
        <Route
          path="/connecting-creativity"
          element={<ConnecetingCreativity />}
        />
        <Route path="/gsg-global-impact-summit" element={<GsgSummit />} />
        <Route path="/ific-bank-joins-nabg" element={<Ific />} />
        <Route path="/on-impact" element={<OnImpact />} />
        <Route
          path="/discussion-decision-determination-nabg-meeting"
          element={<NabMeeting />}
        />
        <Route
          path="/nab-trust-new-leader-for-impact-investment"
          element={<NabTrust />}
        />
        <Route
          path="/partnership-between-nab-bangladesh-and-turkiye-nab"
          element={<Partnership />}
        />
        <Route path="/nab-trust" element={<ActualNabTrust />} />
        <Route
          path="/father-of-impact-investment"
          element={<FatherOfImpactInvestment />}
        />
        <Route path="/new-wave-of-nab-bangladesh" element={<NewWave />} />
        <Route path="/nab-bangladesh-launches-biisap" element={<BisapNews />} />
        <Route path="/gsg-global-impact-summit-2021" element={<GsgNews />} />
        <Route
          path="/impact-investart-and-bridging"
          element={<ImpactInvestart />}
        />
        <Route path="/bridging-the-gap" element={<BridgingGap />} />
        <Route path="/impact-auditing" element={<ImpactAudit />} />
        <Route
          path="/insights-from-the-nab-bangladesh-conference"
          element={<EmpoweringImpact />}
        />
        <Route
          path="/factstats_esc-vs-impact-investment"
          element={<FactstatsVsImpact />}
        />
        <Route
          path="/national-impact-investment-day"
          element={<NationalImpactInvest />}
        />
        <Route
          path="/fostering-sustainable-development"
          element={<FosteringDevelopment />}
        />
        <Route
          path="/10-november:national-impact-investment-day"
          element={<NationalImpactNovember />}
        />
        <Route
          path="/are-they-time-travellers-from-the-future"
          element={<TimeTravellers />}
        />
        <Route
          path="/formation-of-national-advisory"
          element={<NationalAdvisory />}
        />
        <Route
          path="/first-impact-fund-raising-initiative-in-bangladesh"
          element={<FirstFundRaising />}
        />
        <Route
          path="/navigating-impact-investment-in-bangladesh"
          element={<NavigatingImpact />}
        />
        <Route
          path="/investment/navigating-impact-investment-in-Bangladesh"
          element={<InvestmentImpact />}
        />
        <Route
          path="/impact-investime:national-impact-investment-day-2024"
          element={<NationalImpact />}
        />
        <Route
          path="/the-plan-to-break-systemic-inertia-and-deliver-impact-transparency"
          element={<PlanBreak />}
        />
        <Route path="/a-strong-base-for-growth" element={<StrongGrowth />} />
        <Route path="/hnb-and-liin-partner" element={<HnbAndLiin />} />
        <Route
          path="/bangladesh:trillion-dollar-economy"
          element={<TrillionEconomy />}
        />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/admin-login" element={<AdminLogin />} />

        {/* adminRoute */}
        <Route
          path="/admin"
          element={
            <ProtectedRoute>
              <Admin />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/join-form-table"
          element={
            <ProtectedRoute>
              <AdminJoinFormTable />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/contact-table"
          element={
            <ProtectedRoute>
              <AdminJoinFormContactTable />
            </ProtectedRoute>
          }
        />
      </Routes>

      {height > 1200 && (
        <div
          className="fixed bottom-[20%] right-0 w-[45px] h-[45px] rounded-l-md bg-gray-400 flex justify-center items-center cursor-pointer"
          onClick={handleTop}
        >
          <FaAngleUp className="fill-white w-6 h-6" />
        </div>
      )}
    </>
  );
}

export default App;
