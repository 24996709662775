import members1 from "../../assets/images/members1.png";
import members2 from "../../assets/images/members2.png";
import members3 from "../../assets/images/members3.png";
import members4 from "../../assets/images/members4.png";
import members5 from "../../assets/images/members5.png";
import members6 from "../../assets/images/members6.png";
import members7 from "../../assets/images/members7.png";
import members8 from "../../assets/images/members8.png";
import members9 from "../../assets/images/members9.png";
import members10 from "../../assets/images/members10.png";
import members11 from "../../assets/images/members11.png";
import members12 from "../../assets/images/members12.png";
import members13 from "../../assets/images/members13.png";
import ANDE from "../../assets/images/ANDE.png";
import Sajida from "../../assets/images/Sajida.png";
import startup from "../../assets/images/startup.png";
import world_vission from "../../assets/images/world_vission.png";
import Epyllion from "../../assets/images/Epyllion.png";
import LightCastle from "../../assets/images/LightCastle.png";
import BWCCI from "../../assets/images/BWCCI.png";
import International_Finance_Corporation_logo from "../../assets/images/International_Finance_Corporation_logo.png";
import ICLLogo from "../../assets/images/ICL_Logo.png";
import ific_Logo from "../../assets/images/ific_Logo.png";
import B_LOGO from "../../assets/images/B_LOGO.png";

import publications1 from "../../assets/images/publications1.jpg";
import publications2 from "../../assets/images/publications2.png";
import NAB_Report_2023_Cover from "../../assets/images/NAB_Report_2023_Cover.jpg";
import Cover_Impact_InveSTART_2023 from "../../assets/images/Cover_Impact_InveSTART_2023.jpg";
import InveSTAR_PUB_Cover from "../../assets/images/InveSTAR_PUB_Cover.jpg";

export const membersData = [
  {
    id: "01",
    img: members1,
  },
  {
    id: "02",
    img: members2,
  },
  {
    id: "04",
    img: members4,
  },
  {
    id: "03",
    img: members3,
  },
  {
    id: "21",
    img: startup,
  },
  {
    id: "07",
    img: members7,
  },
  {
    id: "06",
    img: members6,
  },
  {
    id: "22",
    img: world_vission,
  },
  {
    id: "24",
    img: BWCCI,
  },
  {
    id: "010",
    img: members10,
  },
  {
    id: "14",
    img: International_Finance_Corporation_logo,
  },
  {
    id: "13",
    img: members13,
  },
  {
    id: "33",
    img: ANDE,
  },
  {
    id: "34",
    img: Sajida,
  },
  {
    id: "15",
    img: ICLLogo,
  },
  {
    id: "16",
    img: ific_Logo,
  },

  {
    id: "08",
    img: members8,
  },
  {
    id: "22",
    img: Epyllion,
  },
  {
    id: "23",
    img: LightCastle,
  },

  {
    id: "17",
    img: B_LOGO,
  },
  //   {
  //     id: "09",
  //     img: members9,
  //   },
];

export const publicationsData = [
  {
    id: "01",
    title: "Impact InveSTART",
    image: Cover_Impact_InveSTART_2023,
    pdf: "https://buildbangladeshbd-my.sharepoint.com/personal/ekraz_buildbangladesh_org_bd/_layouts/15/onedrive.aspx?id=%2Fpersonal%2Fekraz%5Fbuildbangladesh%5Forg%5Fbd%2FDocuments%2FNAB%20Directory%2FNAB%20Webpage%20update%2FNAB%20WEB%20Update%2010%2FPublication%20Files%2FImpact%20InveSTART%202023%2Epdf&parent=%2Fpersonal%2Fekraz%5Fbuildbangladesh%5Forg%5Fbd%2FDocuments%2FNAB%20Directory%2FNAB%20Webpage%20update%2FNAB%20WEB%20Update%2010%2FPublication%20Files&ga=1",
  },
  {
    id: "02",
    title: "NAB Annual Report 2023",
    image: NAB_Report_2023_Cover,
    pdf: "https://firebasestorage.googleapis.com/v0/b/nab-bangladesh-webapplication.appspot.com/o/NAB%20Report%202023.pdf?alt=media&token=cd9fe8d4-2ff1-4441-a6c5-24c7fd58a779",
  },
  
];
